export enum Path {
  COMPANY = 'company',
  BRANCHES = `company/branches`,
  SERVICES = 'services',
  DETAILS = 'details',
  CLIENTS = 'clients',
  EMPLOYEES = 'employees',
  CALENDAR = 'calendar',
  BOOKING = 'booking',
  PROFILE = 'profile',
  ONBOARD = 'onboard',
  MENU = 'menu',
  TIMETABLE = 'timetable',
  SUBSCRIPTIONS = 'subscriptions'
}
